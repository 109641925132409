import {Box, InputAdornment, Menu, MenuItem, TextField, ToggleButton, Typography} from "@mui/material";
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import React, {useState} from "react";
import SearchIcon from "@mui/icons-material/Search";
import {useLocation, useNavigate} from "react-router-dom";
import FrontendRoutes from "../../constants/FrontendRoutes";

function Header() {

    const [alignment, setAlignment] = useState('PayPenny');
    const [anchorEl, setAnchorEl] = useState(null);
    const navigate = useNavigate();


    const handleClick = (event) => {
        if (anchorEl === null) {
            setAnchorEl(event.currentTarget);
        } else {
            setAnchorEl(null)
        }

    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleAlignment = (event, newAlignment) => {
        if (newAlignment !== null) {
            setAlignment(newAlignment);
        }
    };

    const onChangePassword = () => {
        navigate(FrontendRoutes.auth.RECOVER)
    }

    const onLogout = () => {
        navigate(FrontendRoutes.auth.LOGIN)
        localStorage.setItem('userInfo', '')
    }

    const location = useLocation();
    const user =  JSON.parse(localStorage.getItem('userInfo'))


    return (
        <Box
            sx={{
                width: '100%',
                height: '64px',
                bgcolor: '#fff',
                display: 'flex',
                alignItems: 'center',
                justifyContent: (location.pathname === FrontendRoutes.general.DASHBOARD_PAGE || location.pathname === FrontendRoutes.general.YAPILY_PAGE) ? 'space-between' : 'flex-end',
                px: 3,
                // boxShadow: '0 4px 4px rgba(0, 0, 0, 0.1)',
                border: 'none'
            }}
        >
            {location.pathname !== FrontendRoutes.general.USER_MANAGEMENT_PAGE &&
                <>
                    <TextField
                        placeholder="Search..."
                        variant="outlined"
                        size="small"

                        sx={{
                            marginLeft: '5px',
                            // width: '34vw',
                            borderColor: '#EAEAEA',
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderColor: '#EAEAEA',
                                },
                                '& input': {
                                    height: '48px',
                                    padding: '0 14px',
                                },
                            },
                        }}
                        InputProps={{
                            sx: {borderRadius: '10px'},
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon/>
                                </InputAdornment>
                            ),
                        }}
                    />
                    <ToggleButtonGroup
                        value={alignment}
                        exclusive
                        onChange={handleAlignment}
                        sx={{
                            '& .MuiToggleButton-root': {
                                border: 'none',
                                borderRadius: '0',
                                // padding: '8px 16px',
                                textTransform: 'none',
                                '&.Mui-selected': {
                                    bgcolor: '#fff',
                                    color: '#49AA81',
                                    borderBottom: '2px solid #49AA81'
                                },
                            },
                        }}
                    >
                        <ToggleButton value="PayPenny">PayPenny</ToggleButton>
                        <ToggleButton value="Peerpe">Peerpe</ToggleButton>
                    </ToggleButtonGroup>
                </>
            }

                <Box sx={{
                    display: 'flex',
                    cursor: 'pointer'
                }} onClick={handleClick}>
                    <Box sx={{height: '40px', width: '40px', bgcolor: 'grey.200', borderRadius: '50%'}}></Box>
                    <Box sx={{paddingLeft: '10px'}}>
                        <Typography sx={{fontSize: '14px', fontWeight: 400}}>{user?.user.firstName} {user?.user.lastName}</Typography>
                        <Typography sx={{fontSize: '10px', fontWeight: 400}}>{user?.user.email}</Typography>
                    </Box>
                    <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                    >
                        <MenuItem onClick={() => { handleClose(); onChangePassword();}}>
                            Change Password
                        </MenuItem>
                        <MenuItem onClick={() => { handleClose(); onLogout();}}>
                            Log Out
                        </MenuItem>
                    </Menu>
                </Box>

        </Box>
    );
}

export default Header
