import {Box, Typography} from "@mui/material";
import React, {useCallback, useState} from "react";
import {useNavigate} from "react-router-dom";
import AuthLayout from "../../components/authLayout/AuthLayout";
import RecoverForm from "./recoverForm/RecoverForm";
import FrontendRoutes from "../../constants/FrontendRoutes";
import API from "../../data/api/API";


function RecoverPasswordPage() {
    const navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [loading, setLoading] = useState(false);



    const handleSubmit = useCallback(() => {
        setLoading(true)

        API.user.recoverPassword({email: email})
            .catch((err) => console.error("Recover failed", err))
            .finally(() => {
            setLoading(false);
        });
    }, [email])


    return (

        <AuthLayout>
            <Box sx={{width: '351px'}}>
                <Typography sx={{
                    fontSize: '24px',
                    fontWeight: 600,
                    marginBottom: '50px'
                }}>
                    Recover password
                </Typography>
                <RecoverForm
                    email={email}
                    setEmail={setEmail}
                    onClick={handleSubmit}
                    loading={loading}
                />
                <Typography sx={{
                    color: '#49AA81',
                    fontSize: '14px',
                    marginTop: '10px',
                    cursor: 'pointer'
                }} onClick={() => navigate(FrontendRoutes.auth.LOGIN)}>
                    Return to the login screen
                </Typography>
            </Box>
        </AuthLayout>


    );
}

export default RecoverPasswordPage;
