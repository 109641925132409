import {Box, Typography} from "@mui/material";
import React, {useCallback, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import AuthLayout from "../../components/authLayout/AuthLayout";
import ResetForm from "./resetForm/ResetForm";
import API from "../../data/api/API";
import FrontendRoutes from "../../constants/FrontendRoutes";


function ResetPasswordPage() {
    const navigate = useNavigate();
    const [confirmPassword, setConfirmPassword] = useState("");
    const [password, setPassword] = useState("");

    const query = useParams();

    const handleSubmit = useCallback(() => {
        if (password !== confirmPassword) {
            alert("Passwords do not match!");
            return;
        }
        if (password.length < 8) {
            alert("Password must be at least 8 characters long!");
            return;
        }
        API.user.changePassword(query.id, {password, confirmPassword})
            .then(() => navigate(FrontendRoutes.auth.LOGIN))
            .catch((err) => console.error("Registration failed", err));
    }, [password, confirmPassword])


    return (

        <AuthLayout>
            <Box sx={{width: '351px'}}>
                <Typography sx={{
                    fontSize: '24px',
                    fontWeight: 600,
                    marginBottom: '50px'
                }}>
                    Create new password
                </Typography>
                <ResetForm
                    confirmPassword={confirmPassword}
                    password={password}
                    setConfirmPassword={setConfirmPassword}
                    setPassword={setPassword}
                    onClick={handleSubmit}
                />
            </Box>
        </AuthLayout>


    );
}

export default ResetPasswordPage;
