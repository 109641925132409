import {Box, Typography} from "@mui/material";
import React, {useCallback, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import AuthLayout from "../../components/authLayout/AuthLayout";
import RegisterForm from "./registerForm/RegisterForm";
import API from "../../data/api/API";
import FrontendRoutes from "../../constants/FrontendRoutes";


function RegisterPage() {
    const navigate = useNavigate();
    const [confirmPassword, setConfirmPassword] = useState("");
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(false);



    const query = useParams();

    const handleRegister = useCallback(() => {
        if (password !== confirmPassword) {
            alert("Passwords do not match!");
            return;
        }
        if (password.length < 8) {
            alert("Password must be at least 8 characters long!");
            return;
        }
        setLoading(true)
        API.user.registerUser(query?.id, {password, confirmPassword})
            .then(() => navigate(FrontendRoutes.auth.LOGIN))
            .catch((err) => console.error("Registration failed", err))
            .finally(() => {
                setLoading(false);
            });
    }, [password, confirmPassword])


    return (

        <AuthLayout>
            <Box sx={{width: '351px'}}>
                <Typography sx={{
                    fontSize: '24px',
                    fontWeight: 600,
                    marginBottom: '50px'
                }}>
                    Register
                </Typography>
                <RegisterForm
                    confirmPassword={confirmPassword}
                    password={password}
                    setConfirmPassword={setConfirmPassword}
                    setPassword={setPassword}
                    onClick={handleRegister}
                />
            </Box>
        </AuthLayout>


    );
}

export default RegisterPage;
