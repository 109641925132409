import axios from "axios";

const USER_ITEM = "userInfo";

export function saveUserData(userInfo, setCurrentUser) {
	setCurrentUser(userInfo);
	localStorage.setItem(USER_ITEM, JSON.stringify(userInfo));
	setAxiosAuthToken(userInfo.token);
}

export function clearLoginData(setCurrentUser) {
	setCurrentUser && setCurrentUser(null);
	localStorage.removeItem(USER_ITEM);
	clearAxiosAuthToken();
}

export function retrieveSavedLoginData(setCurrentUser) {
	const userInfo = JSON.parse(localStorage.getItem(USER_ITEM) || null);
	if (userInfo !== null) {
		setAxiosAuthToken(userInfo.token);
	}
	// setCurrentUser
	// 	? setCurrentUser(userInfo || null)
	// 	: console.warn(
	// 			"retrieveSavedLoginData: setCurrentUser is not specified"
	// 	  );
	return userInfo || {};
}

export const setAxiosAuthToken = (authToken) => {
	(axios.defaults.headers.common["Authorization"] = `Bearer ${authToken}`);
}

export const clearAxiosAuthToken = () =>
	delete axios.defaults.headers.common["Authorization"];
export const getAxiosAuthToken = () =>
	axios.defaults.headers.common["Authorization"].replace("Bearer ", "");
