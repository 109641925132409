import {Box} from "@mui/material";
import SideBar from "../../components/sideBar/SideBar";
import React from "react";
import Header from "../../components/header/Header";
import {useLocation} from "react-router-dom";
import FrontendRoutes from "../../constants/FrontendRoutes";


function MainLayout({children}) {
    const location = useLocation();

    return (
        <Box sx={{display: 'flex', bgcolor: '#FBFBFB'}}>
            <SideBar/>
            <Box sx={{display: 'flex', flexDirection: 'column', width: '85%'}}>
                <Header/>
                {location.pathname === FrontendRoutes.general.DASHBOARD_PAGE ?
                    <Box sx={{padding: '30px 30px 0 30px'}}>
                        {children}
                    </Box>
                    :
                    <Box sx={{p: '30px'}}>
                        {children}
                    </Box>
                }

            </Box>
        </Box>
    );
}

export default MainLayout;
