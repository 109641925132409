import {createTheme} from "@mui/material/styles";
import InterTtf from "./assets/fonts/Inter-VariableFont.ttf";

const themeBase = createTheme({
    breakpoints: {
        values: {
            xs: 320,
            sm: 600,
            md: 960,
            lg: 1280,
            xl: 1920,
        },
    },
    palette: {
        primary: {
            main: "#49AA81",
            disabled: "#aaa3",
        },
        secondary: {
            main: "#759658",
        },
        text: {
            primary: "#3E4345",
            secondary: "#000000",
            disable: "#999999",
            white: "#fff",
            lightGray: "#DBDBDB",
            dark: "#1B1818",
        },
        background: {
            primary: "#E3E5E6",
            secondary: "#163B50",
            orange: "#FC683F",
            light: "#848484",
            middle: "#555555",
            silver: "#C2C2C2",
            transparent: "transparent",
            white: "#fff",
        },
        0: {
            primary: "#161010",
            secondary: "#272424",
        },
        20: {
            primary: "#201B1B",
            secondary: "#403E3E",
        },
        40: {
            primary: "#2B2727",
            secondary: "#585757",
        },
        60: {
            primary: "#383535",
            secondary: "#707070",
        },
        80: {
            primary: "#454343",
            secondary: "#7B7B7B",
        },
        100: {
            primary: "#555555",
            secondary: "#848484",
        },
    },
    typography: {
        fontFamily: '"Inter", sans-serif',
        allVariants: {
        },
        h2: {
            fontFamily: "Poppins",
            fontStyle: "normal",
            fontWeight: "600",
            fontSize: "36px",
            lineHeight: "40px",
            color: "#DBDBDB",
        },

        h3: {
            fontFamily: "Poppins",
            fontStyle: "normal",
            fontWeight: "600",
            fontSize: "18px",
            lineHeight: "27px",
            color: "#DBDBDB",
        },
        h4: {
            fontFamily: "Poppins",
            fontStyle: "normal",
            fontWeight: "600",
            fontSize: "18px",
            lineHeight: "27px",
            textAlign: "center",
            color: "#1B1818",
        },
        body1: {
            fontFamily: "Inter",
            fontWeight: "400",
            fontSize: "16px",
            lineHeight: "19.36px",
        },
        body2: {
            fontFamily: "Inter",
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "14px",
            lineHeight: "17px",
        },
        subtitle1: {
            fontFamily: "Inter",
            fontStyle: "normal",
            fontWeight: "normal",
            fontSize: "14px",
            lineHeight: "17px",
        },
        subtitle2: {
            fontFamily: "Inter",
            fontStyle: "normal",
            fontWeight: "normal",
            fontSize: "12px",
            lineHeight: "15px",
        },
    },
});

const mainTheme = createTheme({
        ...themeBase,
        components: {
            MuiCssBaseline: {
                styleOverrides: `
            @font-face {
                font-family: 'Inter';
                font-style: normal;
                font-display: swap;
                font-weight: 100 900;
                src: local('Inter'), url(${InterTtf}) format('truetype');
                unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
              }`
            }
        },
        MuiAvatar: {
            styleOverrides: {
                root: {
                    backgroundColor: themeBase.palette.secondary.main,
                },
            },
            variants: [
                {
                    props: {variant: "large"},
                    style: {
                        width: "4rem",
                        height: "4rem",
                        fontSize: "2.5rem",
                    },
                },
            ],
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    fontWeight: themeBase.typography.fontWeightBold,
                },
            },
        },
        MuiCircularProgress: {
            styleOverrides: {
                root: {
                    color: "white",
                    marginLeft: themeBase.spacing(2),
                },
            },
        },
        MuiLink: {
            styleOverrides: {
                root: {
                    fontFamily: "Inter",
                    fontSize: "1em",
                    fontWeight: "400",
                    lineHeight: "19px",
                    textDecoration: "none",
                    color: themeBase.palette.primary.light,
                },
            },
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    width: "100%",
                    "& label": {
                        color: themeBase.palette.text.secondary,
                        opacity: 0.3,
                    },
                    "& label.Mui-focused": {
                        color: "rgba(0, 0, 0, 0.54)",
                    },
                    "& .MuiInput-underline:before": {
                        borderBottom: "1px solid rgba(0, 0, 0, 0.3)",
                    },
                    "& p": {
                        fontSize: "0.75em",
                        position: "absolute",
                        bottom: "-5px",
                        lineHeight: "15px",
                    },
                },
            },
        },
    },
);

export default mainTheme;
