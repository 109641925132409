import {Box, Typography} from "@mui/material";
import React, {useCallback, useState} from "react";
import {useNavigate} from "react-router-dom";
import API from "../../data/api/API";
import {saveUserData} from "../../data/helpers/ApiHelpers";
import LoginForm from "./loginForm/LoginForm";
import FrontendRoutes from "../../constants/FrontendRoutes";
import AuthLayout from "../../components/authLayout/AuthLayout";


function LoginPage() {
    const navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(false);
    const [userInfo, setUserInfo] = useState({})


    const handleLogin = useCallback(() => {
        setLoading(true)
        API.auth.login(email, password)
            .then((res) => {
                saveUserData(res.data, setUserInfo)
                navigate(FrontendRoutes.general.DASHBOARD_PAGE)
            })
            .catch((err) => {
                console.error(err);
            }).finally(() => {
            setLoading(false);
        });
    }, [email, password])


    return (

                <AuthLayout>
                    <Box sx={{width: '351px'}}>
                        <Typography sx={{
                            fontSize: '24px',
                            fontWeight: 600,
                            marginBottom: '50px'
                        }}>
                            Login
                        </Typography>
                        <LoginForm
                            email={email}
                            password={password}
                            setEmail={setEmail}
                            setPassword={setPassword}
                            onClick={handleLogin}
                            loading={loading}
                        />
                    </Box>
                </AuthLayout>


    );
}

export default LoginPage;
