import {Box, Button, CircularProgress} from "@mui/material";
import React from "react";
import TextInput from "../../../components/textInput/TextInput";


function RecoverForm({ email, setEmail, onClick, loading}) {

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column'
        }}>
            <TextInput InputLabelProps={{ shrink: true }} label={'Email'} value={email} setValue={setEmail}/>
            <Button
                sx={{
                    height: '48px',
                    borderRadius: '10px',
                    color: '#fff',
                    textTransform: 'none'
                }}
                onClick={onClick}
                variant="contained"
            >
                {loading ? <CircularProgress size={24} color="inherit" /> : "Send email with recovery link"}
            </Button>

        </Box>
    );
}

export default RecoverForm;
