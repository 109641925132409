import {Box, Typography} from "@mui/material";
import React from "react";
import { ReactComponent as YapilyIcon } from "../../assets/icons/YapilyIcon.svg"
import { ReactComponent as VeriffIcon } from "../../assets/icons/VeriffIcon.svg"
import { ReactComponent as MonovaIcon } from "../../assets/icons/MonovaIcon.svg"
import { ReactComponent as SumsubIcon } from "../../assets/icons/SumsubIcon.svg"

const tabs = [
    {
        logo: <YapilyIcon/>,
        name: "Yapily",
        number: 20,
        rejected: '7',
        pending: 10,
        approved: 3
    },
    {
        logo: <VeriffIcon/>,
        name: "Veriff",
        number: 10,
        rejected: '7',
        pending: 10,
        approved: 3
    },
    {
        logo: <MonovaIcon/>,
        name: "Monova",
        number: 18,
        rejected: '7',
        pending: 10,
        approved: 3
    },
    {
        logo: <SumsubIcon/>,
        name: "Sumsub",
        number: 4,
        rejected: '7',
        pending: 10,
        approved: 3
    }
]


function ApiTabs() {

    return (
        <Box sx={{width: '100%', display: 'flex', justifyContent: 'space-between', marginBottom: '20px'}}>

            {tabs.map(item => (
                    <Box sx={{
                        height: '126px',
                        minWidth: '200px',
                        bgcolor: '#fff',
                        width: '23%',
                        // border: '0.2px solid #D0D5DD',
                        borderTop: 'none',
                        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                        borderRadius: '10px',
                        cursor: 'pointer',
                        '&:hover': {
                            border: '1px solid #49AA81'
                        }
                    }}>
                        <Box sx={{display: 'flex', alignItems: 'center', p: '10px'}}>
                            <Box sx={{
                                marginRight: '8px',
                                paddingLeft: '5px',
                            }}>
                                {item.logo}
                            </Box>
                            {item.name}
                        </Box>
                        <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '5px 15px 0 15px'}}>
                            <Typography sx={{fontWeight: 600, fontSize: '48px', color: '#49AA81'}}>{item.number}</Typography>
                            <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'baseline'}}>
                                <Box sx={{fontSize: '10px', display: 'flex', alignItems: 'center'}}>
                                    <Box sx={{width: '6px', height: '6px', bgcolor: '#FFDCDC', borderRadius: '50%', marginRight: '4px'}}></Box>
                                    <b>{item.rejected}</b> {' '}rejected
                                </Box>
                                <Box sx={{fontSize: '10px', display: 'flex', alignItems: 'center'}}>
                                    <Box sx={{width: '6px', height: '6px', bgcolor: '#FFCD8C', borderRadius: '50%', marginRight: '4px'}}></Box>
                                    <b>{item.pending}</b>{' '}pending
                                </Box>
                                <Box sx={{fontSize: '10px', display: 'flex', alignItems: 'center'}}>
                                    <Box sx={{width: '6px', height: '6px', bgcolor: '#D3EDC8', borderRadius: '50%', marginRight: '4px'}}></Box>
                                    <b>{item.approved} </b> {' '}approved
                                </Box>
                            </Box>
                        </Box>

                    </Box>
                )
            )}
        </Box>


    );
}


export default ApiTabs;
