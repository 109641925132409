import {Box, Typography} from "@mui/material";
import React from "react";
import WomanImg from "../../assets/images/women.jpg";
import lineImg from "../../assets/images/loginLine.jpg";
import {ReactComponent as LogoIcon} from "../../assets/icons/Logo.svg"



function AuthLayout({ children }) {
    return (
        <Box sx={{display: 'flex'}}>
            <Box>
                <div style={{
                    position: 'relative',
                    height: '100vh',
                    width: '554px',
                    backgroundImage: `url(${WomanImg})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat'
                }}/>
                <Box sx={{
                    position: 'absolute',
                    top: '50px',
                    left: '650px',
                    transform: 'translateX(-50%)',
                }}>
                    <LogoIcon/>
                </Box>
            </Box>
            <Box sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundImage: `url(${lineImg})`,
                backgroundSize: 'contain',
                backgroundPosition: 'bottom',
                backgroundRepeat: 'no-repeat'
            }}>
                    {children}
            </Box>

        </Box>
    );
}

export default AuthLayout;
