import React, {useState} from 'react';
import {DateCalendar, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {Box, ToggleButton} from "@mui/material";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

const CustomDatePicker = ({selectedDate, setSelectedDate, onDataFetch, toggleDate, setToggleDate}) => {
    const [alignment, setAlignment] = useState();

    const handleDateChange = (newDate) => {
        if (newDate && newDate.isValid()) {
            setSelectedDate(newDate);
        } else {
            console.error('Invalid date selected');
        }
    };

    const handleMonthChange = (newDate) => {
        if (newDate && newDate.isValid()) {
            setSelectedDate(newDate);
        }
    };

    const handleAlignment = (event, newAlignment) => {
        if (newAlignment !== null) {
            setAlignment(newAlignment);
            onDataFetch(selectedDate, newAlignment);
        }
    };

    return (
        <Box>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Box sx={{
                    position: 'relative',
                    overflow: 'visible',
                    transform: 'translateY(-10px)'
                }}>
                    <DateCalendar
                        sx={{
                            '.MuiDayCalendar-root': {
                                height: "70px",
                                display: 'none',
                            },
                            '.MuiPickersFadeTransitionGroup-root': {
                                backgroundColor: 'white',
                                borderRadius: '10px',
                            },
                            position: 'absolute',
                            zIndex: 1,
                        }}
                        value={selectedDate}
                        onChange={handleDateChange}
                        onMonthChange={handleMonthChange}
                        views={['year', 'month', 'day']}
                    />
                </Box>
            </LocalizationProvider>


            <ToggleButtonGroup
                value={alignment}
                exclusive
                onChange={handleAlignment}
                sx={{
                    position: 'absolute',
                    right: '30px',
                    '& .MuiToggleButton-root': {
                        border: 'none',
                        borderRadius: '0',
                        // padding: '8px 16px',
                        textTransform: 'none',
                        '&.Mui-selected': {
                            bgcolor: '#FBFBFB',
                            color: '#49AA81',
                            borderBottom: '2px solid #49AA81'
                        },
                    },
                }}
            >
                <ToggleButton value="day">Day</ToggleButton>
                <ToggleButton value="week">Week</ToggleButton>
                <ToggleButton value="month">Month</ToggleButton>
                <ToggleButton value="year">Year</ToggleButton>
            </ToggleButtonGroup>
        </Box>
    );
};


export default CustomDatePicker;
