import {Box, Typography} from "@mui/material";
import React from "react";
import LineChartForApi from "../lineChartForApi/LineChartForApi";




function TabsForApiPage({transSum}) {

    const tabs = [
        {
            name: "Sucessfull",
            bgcolor: '#D3EDC8',
            color: '#174006',
            money:  transSum.approved && '$' + transSum.approved
        },
        {
            name: "Pending",
            bgcolor: '#FFCD8C',
            color: '#6B420E',
            money: transSum.pending && '$' + transSum.pending
        },
        {
            name: "Rejected",
            bgcolor: '#FFDCDC',
            color: '#6A1818',
            money: transSum.rejected && '$' + transSum.rejected
        },
    ]

    return (
        <Box sx={{width: '100%', display: 'flex', justifyContent: 'space-between', marginBottom: '20px', marginTop: '40px'}}>
            {tabs.map(item => (
                    <Box sx={{
                        height: '189px',
                        width: '20%',
                        bgcolor: '#fff',
                        borderTop: 'none',
                        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                        borderRadius: '10px',
                        marginRight: '20px'
                    }}>

                        <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', padding: '5px 15px 0 15px'}}>
                            <Box sx={{fontSize: '10px', display: 'flex', alignItems: 'center', mt: '10px'}}>
                                <Box sx={{width: '12px', height: '12px', bgcolor: `${item.bgcolor}`, borderRadius: '50%', marginRight: '4px'}}></Box>
                                <Typography sx={{fontSize: '14px', fontWeight: 500, color: `${item.color}`}}>{item.name}</Typography>
                            </Box>
                            <Box sx={{ pt: '25px', display: 'flex', flexDirection: 'column', alignItems: 'baseline'}}>
                                <Box sx={{fontSize: '10px', display: 'flex', alignItems: 'center'}}>
                                    <Typography sx={{color: '#49AA81', fontSize: '18px', fontWeight: 600}}>{item.money}</Typography>

                                </Box>
                            </Box>
                            <Box sx={{
                                width: '95%',
                                height: '1px',
                                bgcolor: '#D0D5DD',
                                margin: '40px 0 30px 0',
                            }}/>
                            <Typography sx={{fontSize: '14px', fontWeight: 400}}>Deposits</Typography>
                        </Box>

                    </Box>
                )
            )}
            <LineChartForApi transSum={transSum}/>
        </Box>


    );
}


export default TabsForApiPage;
