import axios from "axios";


// const url = 'http://localhost:5000'
const url = 'https://shanchie.xyz'
    //'http://35.182.200.99:5000'


const API = {
    auth: {
        login: (email, password) => axios.post(`${url}/api/auth/login`, { email, password }),
    },
    user: {
        getAllUsers: () => axios.get(`${url}/api/user`),
        createUser: (userInfo) => axios.post(`${url}/api/user/create-user`, userInfo),
        updateUser: (id, user) => axios.put(`${url}/api/user/update/${id}`, user),
        deleteUser: (id) => axios.delete(`${url}/api/user/delete/${id}`),
        registerUser: (id, userInfo) => axios.put(`${url}/api/user/register/${id}`, userInfo),
        recoverPassword: (email) => axios.post(`${url}/api/user/recover`, email),
        changePassword: (id, userInfo) => axios.put(`${url}/api/user/change-password/${id}`, userInfo),

    },
    transaction: {
        getAllTrans: () => axios.get(`${url}/api/transaction`),
    },
    yapily: {
        getAllYapilyData: (date) => axios.get(`${url}/api/yapily`, { params: { selectedMonth: date } }),
        getTransactionSum: (date) => axios.get(`${url}/api/yapily/transaction-sums`, { params: { selectedMonth: date } }),
        getTransactionByPeriod: (start, end) => axios.get(`${url}/api/yapily/transactions`, { params: { start: start, end: end } }),
        getTransactionSumByPeriod: (start, end) => axios.get(`${url}/api/yapily//transaction-sums-period`, { params: { start: start, end: end } }),
    }



};

export default API;
