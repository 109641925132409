import React from "react";
import {Box} from "@mui/material";
import {useLocation} from "react-router-dom";


const Navigation = ({name, onClick, navigation, icon, activeIcon}) => {

    const location = useLocation();


    return (
        <Box
            onClick={onClick}
            sx={{
                cursor: 'pointer',
                padding: '10px 20px',
                fontSize: '14px',
                fontWeight: 500,
                display: 'flex',
                alignItems: 'center',
                '&:hover': {bgcolor: 'grey.200'},
            }}
        >
            <Box sx={{
                marginRight: '8px',
                paddingLeft: '5px',
                borderLeft: location.pathname === navigation ? '2px solid #49AA81' : 'none',
                marginLeft: location.pathname === navigation ? '-2px' : '',
            }}>
                {location.pathname === navigation ? activeIcon : icon}
            </Box>
            {name}
        </Box>
    );
};

export default Navigation;
