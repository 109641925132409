import {Box, Button, CircularProgress, InputAdornment, TextField, Typography} from "@mui/material";
import React, {useEffect, useMemo, useState} from "react";
import MainLayout from "../mainLayout/MainLayout";
import SearchIcon from "@mui/icons-material/Search";
import TableWithCheckbox from "../../components/tableWithCheckbox/TableWithCheckbox";
import ActionsBtn from "../userManagementPage/actions/ActionsBtn";
import API from "../../data/api/API";
import {retrieveSavedLoginData} from "../../data/helpers/ApiHelpers";
import {DateFormatter} from "../../data/helpers/DateFormatter";
import ApiTabs from "../../components/apiTabs/ApiTabs";
import {useGridApiRef} from "@mui/x-data-grid";


function DashboardPage() {
    const [transactions, setTransactions] = useState([])
    const [loading, setLoading] = useState(false)
    const [search, setSearch] = useState('')

    const apiRef = useGridApiRef();

    const handleFilterButtonClick = () => {
        if (apiRef.current) {
            apiRef.current.showFilterPanel();
        }
    };

    const columns = [
        {field: 'userName', headerName: 'Receiver', flex: 2},
        {field: 'type', headerName: 'Type', flex: 2},
        {
            field: 'status',
            headerName: 'Status',
            flex: 2,
            renderCell: (params) => {
                let color;
                switch (params.value) {
                    case 'approved':
                        color = '#A8DB9382';
                        break;
                    case 'rejected':
                        color = '#FFDCDC';
                        break;
                    case 'pending':
                        color = '#FFCD8C';
                        break
                    default:
                        color = '#FFDCDC';
                }
                return <span
                    style={{
                        backgroundColor: color,
                        padding: '4px 10px 4px 10px',
                        borderRadius: '10px',
                        color: '#000'
                    }}>{params.value}</span>
            }
        },
        {
            field: 'date', headerName: 'Date', flex: 2,
            renderCell: (params) => {
                return <span>{DateFormatter(params.value)}</span>
            }
        },
        {
            field: 'amount', headerName: 'Amount', flex: 2,
            renderCell: (params) => {
                return <span>${params.value}</span>
            }
        },

        {
            field: 'actions',
            headerName: 'Actions',
            flex: 2,
            renderCell: (params) => <ActionsBtn/>,
        },
    ];


    useEffect(() => {
        setLoading(true)
        retrieveSavedLoginData()

        API.transaction.getAllTrans()
            .then((res) => {
                setTransactions(res.data)
            }).finally(() => setLoading(false))
    }, [])


    const filteredTrans = useMemo(() => {
        return transactions?.filter(transaction =>
            transaction.userName.toLowerCase().includes(search.toLowerCase())
        ) || [];
    }, [search, transactions]);


    return (
        <MainLayout>

            <Box
                sx={{
                    width: '100%',
                    height: 'auto',
                    bgcolor: '#FBFBFB',
                    // height: "calc(100vh - 100px)",
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <ApiTabs/>
                <Box
                    sx={{
                        flexGrow: 1,
                        height: '690px',
                        display: 'flex',
                        flexDirection: 'column',
                        bgcolor: '#ffff',
                        borderRadius: '10px',
                        // border: '0.2px solid #D0D5DD',
                        borderTop: 'none',
                        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)'
                    }}
                >
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        padding: '20px 0 20px 0'
                    }}>
                        <Typography
                            sx={{fontWeight: 600, fontSize: '24px', marginLeft: '15px'}}>Transactions</Typography>
                        <Box sx={{
                            display: 'flex'
                        }}>
                            <Button variant="outlined"
                                    sx={{
                                        height: '48px',
                                        borderRadius: '10px',
                                        marginRight: '20px',
                                        borderColor: '#EAEAEA',
                                    }}
                                    onClick={handleFilterButtonClick}
                            >
                                Filter
                            </Button>
                            <TextField
                                placeholder="Search..."
                                variant="outlined"
                                size="small"
                                value={search}
                                onChange={(e)=>setSearch(e.target.value)}
                                sx={{
                                    marginRight: '20px',
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            borderColor: '#EAEAEA',
                                        },
                                        '& input': {
                                            height: '48px',
                                            padding: '0 14px',
                                        },
                                    },
                                }}
                                InputProps={{
                                    sx: {borderRadius: '10px'},
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon/>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Box>
                    </Box>
                    <div style={{width: 'inherit', height: '88%'}}>
                        {loading && <CircularProgress color="secondary" sx={{marginLeft: 1}} size={20}/>}
                        {transactions &&
                            <TableWithCheckbox columns={columns} rows={filteredTrans} apiRef={apiRef} checkboxSelection/>
                        }

                    </div>

                </Box>
            </Box>
        </MainLayout>

    );
}

export default DashboardPage;
