import {Box, Button, InputAdornment, TextField, Typography} from "@mui/material";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import SearchIcon from '@mui/icons-material/Search';
import MainLayout from "../mainLayout/MainLayout";
import {useGridApiRef} from "@mui/x-data-grid";
import ActionsBtn from "./actions/ActionsBtn";
import API from "../../data/api/API";
import {retrieveSavedLoginData} from "../../data/helpers/ApiHelpers";
import InviteUserModal from "./inviteUserModal/InviteUserModal";
import TableWithCheckbox from "../../components/tableWithCheckbox/TableWithCheckbox";


function UserManagementPage() {
    const [users, setUsers] = useState([])
    const [loading, setLoading] = useState(false)
    const [editingUserId, setEditingUserId] = useState(null);
    const [search, setSearch] = useState('')

    const apiRef = useGridApiRef();

    const handleFilterButtonClick = () => {
        if (apiRef.current) {
            apiRef.current.showFilterPanel();
        }
    };

    const columns = [
        {field: 'firstName', headerName: 'First name', flex: 1},
        {field: 'lastName', headerName: 'Last name', flex: 1},
        {field: 'email', headerName: 'Email', flex: 1},
        {field: 'role', headerName: 'Role', flex: 1},
        {field: 'application', headerName: 'Application', flex: 1},
        {
            field: 'status',
            headerName: 'Status',
            flex: 2,
            renderCell: (params) => {
                let color;
                switch (params.value) {
                    case 'invited':
                        color = '#FFCD8C';
                        break;
                    case 'confirmed':
                        color = '#A8DB9382';
                        break;
                    case 'unconfirmed':
                        color = '#FFDCDC';
                        break
                    default:
                        color = '#FFDCDC';
                }
                return <span
                    style={{
                        backgroundColor: color,
                        padding: '4px 10px 4px 10px',
                        borderRadius: '10px',
                        color: '#000'
                    }}>{params.value}</span>
            }
        },
        {
            field: 'actions',
            headerName: 'Actions',
            flex: 1,
            renderCell: (params) => <ActionsBtn onEdit={() => handleEdit(params.row)}
                                                onDelete={() => handleDelete(params.row.id)}/>,
        },
    ];

    const [userInfo, setUserInfo] = useState({
        firstName: '',
        lastName: '',
        email: '',
        role: '',
        application: ''
    })

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);

    const handleClose = () => {
        setOpen(false);
        setEditingUserId('')
        setUserInfo({
            firstName: '',
            lastName: '',
            email: '',
            role: '',
            application: ''
        })
    }

    const inviteUserBtn = useCallback(() => {
        const action = editingUserId ? API.user.updateUser(editingUserId, userInfo) : API.user.createUser(userInfo);
        setLoading(true)
        action
            .then(() => {
                API.user.getAllUsers()
                    .then((res) => {
                        setUsers(res.data)
                    }).finally(() => setLoading(false))
                setOpen(false)
            }).finally(() => {
            setUserInfo({
                firstName: '',
                lastName: '',
                email: '',
                role: '',
                application: ''
            })
        })
    }, [userInfo])


    useEffect(() => {
        setLoading(true)
        retrieveSavedLoginData()

        API.user.getAllUsers()
            .then((res) => {
                setUsers(res.data)
            }).finally(() => setLoading(false))
    }, [])

    const handleEdit = (user) => {
        setUserInfo(user);
        setEditingUserId(user.id);
        handleOpen();
    };

    const handleDelete = useCallback((id) => {
        API.user.deleteUser(id)
            .then(() => {
                API.user.getAllUsers()
                    .then((res) => {
                        setUsers(res.data)
                    })
            })
    }, []);

    const filteredUsers = useMemo(() => {
        return users.users?.filter(user =>
            user.firstName.toLowerCase().includes(search.toLowerCase()) ||
            user.lastName.toLowerCase().includes(search.toLowerCase()) ||
            user.email.toLowerCase().includes(search.toLowerCase())
        ) || [];
    }, [search, users.users]);

    return (
        <MainLayout>
            <Box
                sx={{
                    width: '100%',
                    height: '100%',
                    bgcolor: '#FBFBFB',
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <Box
                    sx={{
                        flexGrow: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        bgcolor: '#ffff',
                        borderRadius: '10px',
                        // border: '0.2px solid #D0D5DD',
                        borderTop: 'none',
                        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)'
                    }}
                >
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        padding: '20px 0 20px 0'
                    }}>
                        <Typography sx={{fontWeight: 600, fontSize: '24px', marginLeft: '15px'}}>Users</Typography>
                        <Box sx={{
                            display: 'flex'
                        }}>
                            <Button variant="contained"
                                    onClick={handleOpen}
                                    sx={{
                                        height: '48px',
                                        borderRadius: '10px',
                                        color: '#fff',
                                        textTransform: 'none',
                                        marginRight: '20px'
                                    }}>
                                + Invite a new user
                            </Button>
                            <Button variant="outlined"
                                    sx={{
                                        height: '48px',
                                        borderRadius: '10px',
                                        marginRight: '20px',
                                        borderColor: '#EAEAEA',
                                    }}
                                    onClick={handleFilterButtonClick}
                            >
                                Filter
                            </Button>
                            <TextField
                                placeholder="Search..."
                                variant="outlined"
                                size="small"
                                value={search}
                                onChange={(e)=>setSearch(e.target.value)}
                                sx={{
                                    marginRight: '20px',
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            borderColor: '#EAEAEA',
                                        },
                                        '& input': {
                                            height: '48px',
                                            padding: '0 14px',
                                        },
                                    },
                                }}
                                InputProps={{
                                    sx: {borderRadius: '10px'},
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon/>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Box>
                    </Box>
                    <div style={{width: 'inherit'}}>
                        {/*{loading && <CircularProgress color="secondary" sx={{marginLeft: 1}} size={20}/>}*/}
                        {users.users &&
                            <TableWithCheckbox
                                columns={columns}
                                rows={filteredUsers}
                                apiRef={apiRef}
                                checkboxSelection
                            />
                        }

                    </div>

                    <InviteUserModal
                        open={open}
                        onClose={handleClose}
                        value={userInfo}
                        setValue={setUserInfo}
                        submit={inviteUserBtn}
                        editingUserId={editingUserId}
                        loading={loading}
                    />

                </Box>
            </Box>
        </MainLayout>
    );
}

export default UserManagementPage;
