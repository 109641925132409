import {Box} from "@mui/material";
import React from "react";
import FrontendRoutes from "../../constants/FrontendRoutes";
import {useNavigate} from "react-router-dom";
import Navigation from "./navigation/Navigation";
import {ReactComponent as UsersIcon} from "../../assets/icons/UsersIcon.svg"
import {ReactComponent as UsersIconActive} from "../../assets/icons/UsersIconActive.svg"
import {ReactComponent as DashboardIcon} from "../../assets/icons/DashboardIcon.svg"
import {ReactComponent as DashboardIconActive} from "../../assets/icons/DashboardIconActive.svg"
import {ReactComponent as SettingsIcon} from "../../assets/icons/SettingsIcon.svg"
import {ReactComponent as YapilyIcon} from "../../assets/icons/YapilyIcon.svg"
import {ReactComponent as YapilyIconActive} from "../../assets/icons/YapilyIconActive.svg"
import {ReactComponent as VeriffIcon} from "../../assets/icons/VeriffIcon.svg"
import {ReactComponent as MonovaIcon} from "../../assets/icons/MonovaIcon.svg"
import {ReactComponent as SumsubIcon} from "../../assets/icons/SumsubIcon.svg"
import {ReactComponent as LogoIcon} from "../../assets/icons/Logo.svg"


function SideBar() {
    const navigate = useNavigate();

    return (
        <Box sx={{
            width: '15%',
            bgcolor: '#fff',
            height: '100vh',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            py: 2,
            border: 'none'
        }}>
            <Box sx={{ mb: 3, ml: '20px', mt: '-10px', textAlign: 'left' }}>
                    <LogoIcon/>
            </Box>
            <Box sx={{mt: -35}}>

                <Navigation
                    name={'Dashboard'}
                    icon={<DashboardIcon/>}
                    activeIcon={<DashboardIconActive/>}
                    navigation={FrontendRoutes.general.DASHBOARD_PAGE}
                    onClick={() => navigate(FrontendRoutes.general.DASHBOARD_PAGE)}
                />
                <Navigation
                    name={'Yapily'}
                    icon={<YapilyIcon/>}
                    activeIcon={<YapilyIconActive/>}
                    navigation={FrontendRoutes.general.YAPILY_PAGE}
                    onClick={() => navigate(FrontendRoutes.general.YAPILY_PAGE)}
                />
                <Navigation
                    name={'Veriff'}
                    icon={<VeriffIcon/>}
                    activeIcon={<UsersIconActive/>}
                    // navigation={FrontendRoutes.general.USER_MANAGEMENT_PAGE}
                    // onClick={() => navigate(FrontendRoutes.general.USER_MANAGEMENT_PAGE)}
                />
                <Navigation
                    name={'Monoova'}
                    icon={<MonovaIcon/>}
                    activeIcon={<UsersIconActive/>}
                    // navigation={FrontendRoutes.general.USER_MANAGEMENT_PAGE}
                    // onClick={() => navigate(FrontendRoutes.general.USER_MANAGEMENT_PAGE)}
                />
                <Navigation
                    name={'Sumsub'}
                    icon={<SumsubIcon/>}
                    activeIcon={<UsersIconActive/>}
                    // navigation={FrontendRoutes.general.USER_MANAGEMENT_PAGE}
                    // onClick={() => navigate(FrontendRoutes.general.USER_MANAGEMENT_PAGE)}
                />
                <Box sx={{
                    width: '85%',
                    height: '1px',
                    bgcolor: '#D0D5DD',
                    margin: '20px 0 20px 0',
                    marginLeft: '15px'
                }}/>
                <Navigation
                    name={'User management'}
                    icon={<UsersIcon/>}
                    activeIcon={<UsersIconActive/>}
                    navigation={FrontendRoutes.general.USER_MANAGEMENT_PAGE}
                    onClick={() => navigate(FrontendRoutes.general.USER_MANAGEMENT_PAGE)}
                />
            </Box>
            <Box sx={{ mt: 3, textAlign: 'center' }}>
                <Navigation
                    name={'Settings'}
                    icon={<SettingsIcon/>}
                />
            </Box>

        </Box>

    );
}

export default SideBar;
