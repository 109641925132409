import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import Root from "./pages/root/Root";
import {BrowserRouter} from "react-router-dom";
import {CssBaseline, ThemeProvider} from "@mui/material";
import mainTheme from "./mainTheme";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <BrowserRouter>
        <ThemeProvider theme={mainTheme}>
            <CssBaseline />
            <Root/>
        </ThemeProvider>
    </BrowserRouter>
);


reportWebVitals();
