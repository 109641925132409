import React, { useState } from "react";
import { IconButton, Menu, MenuItem } from "@mui/material";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

function ActionsBtn({ onEdit, onDelete }) {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <IconButton
                sx={{ zIndex: 99 }}
                size="large"
                onClick={handleClick}
                aria-label="actions"
            >
                <MoreHorizIcon />
            </IconButton>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem onClick={() => { handleClose(); onEdit(); }}>Edit</MenuItem>
                <MenuItem onClick={() => { handleClose(); onDelete(); }}>Delete</MenuItem>

            </Menu>
        </>
    );
}

export default ActionsBtn;
