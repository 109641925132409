import {Box, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import {LineChart} from "@mui/x-charts";


const xData = ['1 Oct', '3 Oct', '7 Oct', '10 Oct', '15 Oct', '20 Oct'];
const seriesData = [2, 5.5, 2, 8.5, 1.5, 5];


function LineChartForApi({transSum}) {
    const [chartData, setChartData] = useState({ xData: [], seriesData: [] });


    useEffect(() => {

        const dailyData = transSum.dailyData
        if (transSum.dailyData) {
            const xData = Object.keys(dailyData).map(date => new Date(date).toLocaleDateString('en-US', {
                day: 'numeric',
                month: 'short'
            }));
            const seriesData = Object.values(dailyData);

            setChartData({xData, seriesData});
        }


    }, []);


    return (

        <Box sx={{
            height: '189px',
            bgcolor: '#fff',
            width: '516px',
            borderTop: 'none',
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
            borderRadius: '10px',
            cursor: 'pointer',
            pointerEvents: 'none',
        }}>
            <Typography sx={{fontSize: '14px', fontWeight: 500, pl: '15px', mt: '15px'}}>Sucessfull Deposits</Typography>

            <LineChart
                sx={{
                    '& .css-o84477-MuiChartsAxis-root-MuiChartsYAxis-root .MuiChartsAxis-line' : {
                        stroke: 'none'
                    },
                    '& .css-en3tlt-MuiChartsAxis-root-MuiChartsXAxis-root .MuiChartsAxis-line': {
                        stroke: 'none'
                    },
                    '& .MuiChartsAxis-tick': {
                        display: 'none'
                    },
                    '& .MuiLineElement-root': {
                        stroke: '#49AA81'
                    },
                    '& .MuiMarkElement-root': {
                        display: 'none'
                    },
                }}
                disableAxisListener
                xAxis={[
                    {
                        data: chartData.xData,
                        scaleType: 'band',
                        hideTooltip: true
                    },
                ]}
                series={[
                    {
                        data: chartData.seriesData,
                    },
                ]}
                lineHighlight={false}
                tooltip={{trigger: 'none'}}
                width={516}
                height={169}
            />

        </Box>


    );
}

export default LineChartForApi;
