const FrontendRoutes = {
    general: {
        DASHBOARD_PAGE: "/dashboard",
        USER_MANAGEMENT_PAGE: "/user-management",
        YAPILY_PAGE: "/yapily-dashboard"
    },
    auth: {
        LOGIN: "/login",
        REGISTER: "/register/:id",
        RECOVER: "/recover-password",
        RESET: "/reset-password/:id",
    },
};

export default FrontendRoutes
