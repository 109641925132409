import {Box, Button} from "@mui/material";
import React from "react";
import TextInput from "../../../components/textInput/TextInput";


function RegisterForm({confirmPassword, password, setConfirmPassword, setPassword, onClick}) {

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column'
        }}>
            <TextInput InputLabelProps={{ shrink: true }} label={'Password'} value={password} setValue={setPassword} type={'password'}/>
            <TextInput InputLabelProps={{ shrink: true }} label={'Confirm Password'} value={confirmPassword} setValue={setConfirmPassword} type={'password'}/>
            <Button
                sx={{
                    height: '48px',
                    borderRadius: '10px',
                    color: '#fff',
                    textTransform: 'none'
                }}
                onClick={onClick}
                variant="contained"
            >
                Register
            </Button>

        </Box>
    );
}

export default RegisterForm;
