import {Box, Button, CircularProgress, Modal, Typography} from "@mui/material";
import React from "react";
import TextInput from "../../../components/textInput/TextInput";
import MainSelect from "../../../components/MainSelect/MainSelect";


function InviteUserModal({open, onClose, value, setValue, submit, editingUserId, loading}) {


    const handleInputChange = (field) => (event) => {
        setValue({
            ...value,
            [field]: event.target.value,
        });
    };

    const handleSelectChange = (field) => (event) => {
        setValue({
            ...value,
            [field]: event.target.value,
        });
    };


    return (

        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '804px',
                height: '450px',
                bgcolor: 'background.paper',
                borderRadius: '10px',
                boxShadow: 24,
                p: 4,
            }}>
                <Typography sx={{marginBottom: '50px', fontWeight: 600, fontSize: '24px'}} id="modal-modal-title">
                    Invite a new user
                </Typography>
                <Box sx={{display: 'flex', flexDirection: 'column', gap: 2, height: '100%'}}>
                    <Box sx={{display: 'flex', gap: 2}}>
                        <TextInput fullWidth label={"First Name"} variant="outlined" value={value.firstName}
                                   onChange={handleInputChange('firstName')}/>
                        <TextInput fullWidth label={"Last Name"} variant="outlined" value={value.lastName}
                                   onChange={handleInputChange('lastName')}/>
                    </Box>
                    <Box sx={{display: 'flex', gap: 2}}>
                        <TextInput fullWidth label="Email" variant="outlined" value={value.email}
                                   onChange={handleInputChange('email')}/>
                        <MainSelect
                            value={value.role}
                            onChange={handleSelectChange('role')}
                            label="Role"
                            options={[
                                { value: 'ADMIN', label: 'Admin' },
                                { value: 'USER', label: 'User' },
                            ]}
                        />
                    </Box>
                    <MainSelect value={value.application}
                                onChange={handleSelectChange('application')}
                                label="Applications"
                                options={[
                                    {value: 'PayPenny', label: 'PayPenny'},
                                    {value: 'Peerpe', label: 'Peerpe'},
                                ]}/>
                    <Box sx={{display: 'flex', justifyContent: 'flex-end', mt: 2}}>
                        {editingUserId ?
                            <Button
                                sx={{
                                    height: '48px',
                                    borderRadius: '10px',
                                    color: '#fff',
                                    textTransform: 'none'
                                }}
                                variant="contained"
                                onClick={submit}
                            >

                                Edit
                            </Button>
                            :
                            <Button
                                sx={{
                                    height: '48px',
                                    borderRadius: '10px',
                                    color: '#fff',
                                    textTransform: 'none'
                                }}
                                variant="contained"
                                onClick={submit}
                            >

                                {loading ? <CircularProgress size={24} color="inherit" /> : 'Send an invitation'}
                            </Button>
                        }

                    </Box>
                </Box>
            </Box>
        </Modal>
    );
}

export default InviteUserModal;
