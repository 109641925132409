import {Box, Button, CircularProgress, InputAdornment, TextField, Typography} from "@mui/material";
import React from "react";
import TabsForApiPage from "./tabsForApiPage/TabsForApiPage";
import TableWithCheckbox from "../tableWithCheckbox/TableWithCheckbox";
import {DateFormatterWithTime} from "../../data/helpers/DateFormatter";
import SearchIcon from "@mui/icons-material/Search";
import CustomDatePicker from "../customDatePicker/CustomDatePicker";


const columns = [
    {
        field: 'serial',
        headerName: 'Sr. No.',
        flex: 1
    },
    {field: 'id', headerName: 'Transaction ID', flex: 2},
    {field: 'ukcNumber', headerName: 'UKC No.', flex: 2},
    {field: 'mobileNumber', headerName: 'Mobile No.', flex: 2},

    {
        field: 'createdAt', headerName: 'Date & Time', flex: 2,
        renderCell: (params) => {
            return <span>{DateFormatterWithTime(params.value)}</span>
        }
    },
    {field: 'bic', headerName: 'BIC', flex: 2},
    {field: 'userName', headerName: 'Customer Name', flex: 2},
    {field: 'reference_number', headerName: 'Yapily References', flex: 2},
    {field: 'amount', headerName: 'Deposit Request', flex: 2,},
    // {field: 'amount', headerName: 'Deposit Approved', flex: 2},
    {
        field: 'status',
        headerName: 'Status',
        flex: 2,
        renderCell: (params) => {
            let color;
            switch (params.value.toLowerCase()) {
                case 'COMPLETED':
                    color = '#A8DB9382';
                    break;
                case 'FAILED':
                    color = '#FFDCDC';
                    break;
                case 'failed':
                    color = '#FFDCDC';
                    break;
                case 'PENDING':
                    color = '#FFCD8C';
                    break;
                case 'completed':
                    color = '#A8DB9382';
                    break;
                case 'rejected':
                    color = '#FFDCDC';
                    break;
                case 'pending':
                    color = '#FFCD8C';
                    break
                default:
                    color = '#FFDCDC';
            }
            return <span
                style={{
                    backgroundColor: color,
                    padding: '4px 10px 4px 10px',
                    borderRadius: '10px',
                    color: '#000'
                }}>{params.value.toLowerCase()}</span>
        }
    },

];





function MainApiLayout({data, transSum, loading, selectedDate, setSelectedDate, onDataFetch, toggleDate, setToggleDate, handleFilterButtonClick, apiRef}) {

    const rowsWithSerialNumbers = data?.map((row, index) => ({
        ...row,
        serial: index + 1,
    }));

    return (

            <Box sx={{
                width: '100%',
                height: 'auto',
                bgcolor: '#FBFBFB',
                display: 'flex',
                flexDirection: 'column',
            }}
            >
                <Box sx={{width: '100%', display: 'flex', justifyContent: 'space-between', marginBottom: '20px'}}>

                    <CustomDatePicker selectedDate={selectedDate} setSelectedDate={setSelectedDate} onDataFetch={onDataFetch} toggleDate={toggleDate}
                                      setToggleDate={setToggleDate}/>
                </Box>
                {loading ?
                    <CircularProgress/> :
                    <TabsForApiPage transSum={transSum} loading={loading}/>}

                <Box
                    sx={{
                        flexGrow: 1,
                        height: '550px',
                        display: 'flex',
                        flexDirection: 'column',
                        bgcolor: '#ffff',
                        borderRadius: '10px',
                        // border: '0.2px solid #D0D5DD',
                        borderTop: 'none',
                        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                        overflow: 'auto',
                    }}
                >
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        padding: '20px 0 20px 0'
                    }}>
                        <Typography
                            sx={{fontWeight: 600, fontSize: '24px', marginLeft: '15px'}}>Transactions</Typography>
                        <Box sx={{
                            display: 'flex'
                        }}>
                            <Button variant="outlined"
                                    sx={{
                                        height: '48px',
                                        borderRadius: '10px',
                                        marginRight: '20px',
                                        borderColor: '#EAEAEA',
                                    }}
                                    onClick={handleFilterButtonClick}
                            >
                                Filter
                            </Button>
                            <TextField
                                placeholder="Search..."
                                variant="outlined"
                                size="small"
                                sx={{
                                    marginRight: '20px',
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            borderColor: '#EAEAEA',
                                        },
                                        '& input': {
                                            height: '48px',
                                            padding: '0 14px',
                                        },
                                    },
                                }}
                                InputProps={{
                                    sx: {borderRadius: '10px'},
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon/>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Box>
                    </Box>
                    <div style={{width: 'inherit', height: '84%'}}>
                        {loading ? <CircularProgress/> :
                            <TableWithCheckbox columns={columns} rows={rowsWithSerialNumbers} apiRef={apiRef} />
                        }
                    </div>

                </Box>
            </Box>


    );
}

export default MainApiLayout;
