import React from "react";
import {styled, FormControl, InputLabel, Select, MenuItem} from "@mui/material";

const CssFormControl = styled(FormControl)(({ theme }) => ({
    '& .MuiOutlinedInput-root': {
        '& select': {
            height: '48px',
            padding: '0 14px',
            display: 'flex',
            alignItems: 'center',
        },
        borderRadius: '10px',
    },
    '& label': {
        color: '#667085',
    },
    '& label.Mui-focused': {
        color: '#49AA81',
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: '#B2BAC2',
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: '#49AA81',
    },
    '& .MuiOutlinedInput-notchedOutline': {
        borderColor: '#B2BAC2',
    },
}));

const MainSelect = ({value, setValue, label, options, ...props}) => {

    // const handleChange = (event) => {
    //     onChange(event);
    // };

    return (
        <CssFormControl fullWidth>
            <InputLabel>{label}</InputLabel>
            <Select
                value={value}
                onChange={(e) => setValue(e.target.value)}
                label={label}
                {...props}
            >
                {options.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                        {option.label}
                    </MenuItem>
                ))}
            </Select>
        </CssFormControl>
    );
};

export default MainSelect;
