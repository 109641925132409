import React, {useEffect, useState} from "react";
import MainLayout from "../mainLayout/MainLayout";
import MainApiLayout from "../../components/mainApiLayout/MainApiLayout";
import {retrieveSavedLoginData} from "../../data/helpers/ApiHelpers";
import API from "../../data/api/API";
import { io } from "socket.io-client";
import dayjs from "dayjs";
import {useGridApiRef} from "@mui/x-data-grid";

// const socket = io("http://localhost:5000");
const socket = io("https://shanchie.xyz");

function YapilyPage({}) {
    const [loading, setLoading] = useState(false)
    const [selectedDate, setSelectedDate] = useState(dayjs(new Date(Date.now())));
    const [data, setData] = useState([])
    const [transSum, setTransSum] = useState({})
    const [toggleDate, setToggleDate] = useState()

    const apiRef = useGridApiRef();

    const handleFilterButtonClick = () => {
        if (apiRef.current) {
            apiRef.current.showFilterPanel();
        }
    };


    useEffect(() => {
        setLoading(true)
        retrieveSavedLoginData()

        API.yapily.getTransactionSum(selectedDate.format('YYYY-MM'))
            .then((res) => {
                setTransSum(res?.data)
            })
        API.yapily.getAllYapilyData(selectedDate.format('YYYY-MM'))
            .then((res) => {
                setData(res.data?.yapilyData)
            }).finally(() => setLoading(false))
        socket.on("connect", () => {
            console.log("Connected to socket server");
        });

        socket.on("transactionUpdated", (updatedTransaction) => {

            setData((prevData) => {
                const updatedData = prevData.map((item) => {
                    if (item.paymentId === updatedTransaction.paymentId) {
                        return { ...item, status: updatedTransaction.status };
                    }
                    return item;
                });
                return updatedData;
            });
        });
        socket.on("getTransactions", (newData) => {
            setData(newData.data);
        });

        socket.on("disconnect", () => {
            console.log("Disconnected from socket server");
        });



        return () => {
            socket.off("connect");
            socket.off("disconnect");
            socket.off("transactionUpdated");
            socket.off("getTransactions");
        };
    }, [selectedDate]);


    const onDataFetch = async (date, period) => {
        setLoading(true);
        try {
            let start, end;

            switch (period) {
                case 'day':
                    start = date.subtract(1, 'day').startOf('day').format('YYYY-MM-DD');
                    end = date.add(1, 'day').endOf('day').format('YYYY-MM-DD');
                    const dayDashboard = await API.yapily.getTransactionSumByPeriod(start, end)
                    setTransSum(dayDashboard.data)
                    const dayResponse = await API.yapily.getTransactionByPeriod(start, end);
                    setData(dayResponse.data);
                    break;

                case 'week':
                    start = date.startOf('week').format('YYYY-MM-DD');
                    end = date.endOf('week').format('YYYY-MM-DD');
                    const weekdDashboard = await API.yapily.getTransactionSumByPeriod(start, end)
                    setTransSum(weekdDashboard.data)
                    const weekResponse = await API.yapily.getTransactionByPeriod(start, end);
                    setData(weekResponse.data);
                    break;

                case 'month':
                    start = date.startOf('month').format('YYYY-MM-DD');
                    end = date.endOf('month').format('YYYY-MM-DD');
                    const monthDashboard = await API.yapily.getTransactionSumByPeriod(start, end)
                    setTransSum(monthDashboard.data)
                    const monthResponse = await API.yapily.getTransactionByPeriod(start, end);
                    setData(monthResponse.data);
                    break;

                case 'year':
                    start = date.startOf('year').format('YYYY-MM-DD');
                    end = date.endOf('year').format('YYYY-MM-DD');
                    const yearDashboard = await API.yapily.getTransactionSumByPeriod(start, end)
                    setTransSum(yearDashboard.data)
                    const yearResponse = await API.yapily.getTransactionByPeriod(start, end);
                    setData(yearResponse.data);
                    break;

                default:
                    break;
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setLoading(false);
        }
    };


    return (
        <MainLayout>
                <MainApiLayout
                    data={data}
                    loading={loading}
                    transSum={transSum}
                    selectedDate={selectedDate}
                    setSelectedDate={setSelectedDate}
                    onDataFetch={onDataFetch}
                    toggleDate={toggleDate}
                    setToggleDate={setToggleDate}
                    handleFilterButtonClick={handleFilterButtonClick}
                    apiRef={apiRef}
                />

        </MainLayout>

    );
}

export default YapilyPage;
