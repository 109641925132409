export const DateFormatter = (dateString) => {
    const date = new Date(dateString);

    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, '0');
    const day = String(date.getUTCDate()).padStart(2, '0');

    const formattedDate = `${year}-${month}-${day}`;

    return formattedDate;
}

export const DateFormatterWithTime = (dateString) => {
    const date = new Date(dateString);

    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, '0');
    const day = String(date.getUTCDate()).padStart(2, '0');

    const hours = String(date.getUTCHours()).padStart(2, '0'); // Години
    const minutes = String(date.getUTCMinutes()).padStart(2, '0'); // Хвилини

    const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}`; // Формат дати з годинами

    return formattedDate;
}

