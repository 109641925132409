import React from "react";
import {DataGrid} from "@mui/x-data-grid";


function TableWithCheckbox({rows, columns, apiRef, ...props }) {

    return (

        <DataGrid
            rows={rows}
            columns={columns}
            apiRef={apiRef}
            initialState={{
                pagination: {
                    paginationModel: {page: 0, pageSize: 10},
                },
            }}
            pageSizeOptions={[5, 10]}
            // checkboxSelection
            sx={{
                width: '100%',
                '& .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-cell:focus-within': {
                    outline: 'none',
                },
            }}
            {...props}
        />

    );
}

export default TableWithCheckbox;
