import React from "react";
import {styled, TextField} from "@mui/material";

const CssTextField = styled(TextField)({
    '& .MuiOutlinedInput-root': {
        '& input': {
            height: '48px',
            padding: '0 14px',
            marginBottom: '4px'
        },
    },
    '& label.Mui-focused': {
        color: '#49AA81',
    },
    '& label': {
        color: '#667085',
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: '#B2BAC2',
    },
});

const TextInput = ({value, setValue, label, type, ...props}) => {

    return (
        <CssTextField
            sx={{marginBottom: '20px'}}
            InputProps={{sx: {borderRadius: '10px'}}}
            label={label}
            value={value}
            onChange={(e) => setValue(e.target.value)}
            type={type}
            {...props}
        />
    );
};

export default TextInput;
