import {Box, CircularProgress} from "@mui/material";
import React, {useState} from "react";
import {Navigate, Route, Routes} from "react-router-dom";
import LoginPage from "../loginPage/LoginPage";
import FrontendRoutes from "../../constants/FrontendRoutes";
import DashboardPage from "../dashboardPage/DashboardPage";
import UserManagementPage from "../userManagementPage/UserManagementPage";
import * as PropTypes from "prop-types";
import RegisterPage from "../registerPage/RegisterPage";
import RecoverPasswordPage from "../recoverPassword/RecoverPasswordPage";
import ResetPasswordPage from "../resetPassword/ResetPasswordPage";
import YapilyPage from "../yapilyPage/YapilyPage";


function CircularProgressWrapper(props) {
    return null;
}

CircularProgressWrapper.propTypes = {children: PropTypes.node};

function Root() {

    const [loading, setLoading] = useState(false)


    return (
        <Box>
            {loading ? (
                <CircularProgressWrapper>
                    <CircularProgress/>
                </CircularProgressWrapper>
            ) : (
                <Routes>
                    <Route path="/" element={<Navigate to={FrontendRoutes.auth.LOGIN}/>}/>
                    <Route path={FrontendRoutes.auth.LOGIN} element={<LoginPage/>}/>
                    <Route path={FrontendRoutes.auth.RECOVER} element={<RecoverPasswordPage/>} />
                    <Route path={FrontendRoutes.auth.REGISTER} element={<RegisterPage/>} />
                    <Route path={FrontendRoutes.auth.RESET} element={<ResetPasswordPage/>} />
                    <Route path={FrontendRoutes.general.DASHBOARD_PAGE} element={<DashboardPage/>}/>
                    <Route path={FrontendRoutes.general.YAPILY_PAGE} element={<YapilyPage/>}/>
                    <Route path={FrontendRoutes.general.USER_MANAGEMENT_PAGE} element={<UserManagementPage/>}/>
                </Routes>
                )}
        </Box>
    );
}

export default Root;
